import EService from '~/parts/core/services/eService';
import { type ReferentialInfo } from "~/parts/referentials";
import { InputType } from '~/parts/core';
import { type Address } from '~/parts/core/common/address';

export const NumberMultiplicative = [
    { value: "bis", text: "Bis" },
    { value: "ter", text: "Ter" },
    { value: "quarter", text: "Quarter" },
    { value: "quinquies", text: "Quinquies" }
];
export const Types = [
    { value: "allee", text: "Allée" },
    { value: "anse", text: "Anse" },
    { value: "avenue", text: "Avenue" },
    { value: "boulevard", text: "Boulevard" },
    { value: "carrefour", text: "Carrefour" },
    { value: "chaussee", text: "Chaussée" },
    { value: "chemin", text: "Chemin" },
    { value: "cite", text: "Cité" },
    { value: "clos", text: "Clos" },
    { value: "cote", text: "Cote" },
    { value: "cour", text: "Cour" },
    { value: "cours", text: "Cours" },
    { value: "degre", text: "Degré" },
    { value: "descente", text: "Descente" },
    { value: "dreve", text: "Drève" },
    { value: "escoussiere", text: "Escoussière" },
    { value: "esplanade", text: "Esplanade" },
    { value: "gaffe", text: "Gaffe" },
    { value: "grand-route", text: "Grand-route" },
    { value: "impasse", text: "Impasse" },
    { value: "liaison", text: "Liaison" },
    { value: "mail", text: "Mail" },
    { value: "montee", text: "Montée" },
    { value: "passage", text: "Passage" },
    { value: "place", text: "Place" },
    { value: "placette", text: "Placette" },
    { value: "pont", text: "Pont" },
    { value: "promenade", text: "Promenade" },
    { value: "quai", text: "Quai" },
    { value: "residence", text: "Résidence" },
    { value: "rang", text: "Rang" },
    { value: "rampe", text: "Rampe" },
    { value: "rond-point", text: "Rond-point" },
    { value: "route", text: "Route" },
    { value: "rue", text: "Rue" },
    { value: "ruelle", text: "Ruelle" },
    { value: "sente", text: "Sente" },
    { value: "sentier", text: "Sentier" },
    { value: "square", text: "Square" },
    { value: "traboule", text: "Traboule" },
    { value: "traverse", text: "Traverse" },
    { value: "venelle", text: "Venelle" },
    { value: "villa", text: "Villa" },
    { value: "voie", text: "Voie" }
];


export default class AddressService extends EService {
    keyBase = "referential/getall/";

    constructor() {
        super();
    }

    static AddressTypeName = "ApiWebApp.Common.Addresses.Api.AddressModel";
    static AddressTypeTypeName = "~local.AddressType";
    static AddressNumberComplement = "~local.AddressNumberComplement";

    static getReferentialsInfos(): Array<ReferentialInfo> {
        return [
            {
                typeName: AddressService.AddressTypeName,
                metaData: [
                    { value: "number", text: "Number", inputOption: { type: InputType.Number } },
                    {
                        value: "numberRepeterIndex", text: "Number complement", inputOption: {
                            type: InputType.Dynamic,
                            allowInlineCreation: false,
                            lookup: { typeName: AddressService.AddressNumberComplement, valueField: "value", textField: "text" }
                        }
                    },
                    {
                        value: "type", text: "Type", inputOption: {
                            type: InputType.Select,
                            lookup: { items: Types, valueField: "value", textField: "text" }
                        }
                    },
                    { value: "name", text: "Name", inputOption: { type: InputType.Text } },
                    { value: "complements", text: "Complements", inputOption: { type: InputType.Text } },
                    { value: "zipCode", text: "Zip code", inputOption: { type: InputType.Text } },
                    { value: "city", text: "City", inputOption: { type: InputType.Text } }
                ]
            },
            {
                typeName: AddressService.AddressTypeTypeName,
                localData: Types,
                metaData: [
                    { value: "value", text: "Value", inputOption: { type: InputType.Text } },
                    { value: "text", text: "Text", inputOption: { type: InputType.Text } }
                ]
            },
            {
                typeName: AddressService.AddressNumberComplement,
                localData: NumberMultiplicative,
                metaData: [
                    { value: "value", text: "Value", inputOption: { type: InputType.Text } },
                    { value: "text", text: "Text", inputOption: { type: InputType.Text } }
                ]
            }
        ];
    }

    static addressListItemText(itemData: Address): string {
        const text = `${itemData.number || ""} ${itemData.numberRepeterIndex || ""} ${itemData.type || ""} ${itemData.name || ""} ${itemData.zipCode || ""} ${itemData.city || ""}`;
        return text.trim();
    }

    async getAll() {
        const addresses = await this.http.get<Array<Address>>('address/getall/');
        return addresses;
    }

    async save(address: Address) {
        const savedAddress = await this.http.post<Address, Address>('address/save/', address);
        return savedAddress;
    }
};
